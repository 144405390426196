<template>
  <div>
    <div class="flix-form-group">
      <backBtn :onClick="function() { onChange() }"/>
    </div>
    <label class="flix-html-label">{{ $tc('message.vat', 1) }}</label>
    <div class="flix-form-group">
      <input type="text" class="flix-form-control" v-if="user.vat" :value="user.vat.vat_number" readonly="true">
    </div>
    <div class="flix-row">
      <div class="flix-col-md-12">
        <div class="flix-form-group">
          <h3 class="flix-html-h3">{{ $t('message.edit', {name: $tc('message.vat', 1)}) }}</h3>
        </div>
      </div>
      <div class="flix-col-md-6">
        <label class="flix-html-label">{{ $t('message.add', {name: $tc('message.vat', 1)}) }} <span class="flix-text-danger">*</span></label>
        <div class="flix-form-group">
          <input v-on:keyup="checkSaveButton" type="text" class="flix-form-control" v-model="newVat" spellcheck="false">
        </div>
      </div>
      <div class="flix-col-md-6">
        <label class="flix-html-label">{{ $t('message.repeat', {name: $tc('message.vat', 1)}) }} <span class="flix-text-danger">*</span></label>
        <div class="flix-form-group">
          <input v-on:keyup="checkSaveButton" type="text" class="flix-form-control" v-model="repeatVat" spellcheck="false">
        </div>
      </div>
    </div>
    <div class="flix-row" :key="saveBtn">
      <div class="flix-col-md-12">
        <div class="flix-form-group">
          <saveBtn :class="{'flix-disabled': !saveBtn}" :settings="{block: true}" :onClick="function () { if (saveBtn) { checkVat() } }">
            <template v-slot:text>
              {{ $t('message.save', {name: $tc('message.vat', 1)}) }}
            </template>
          </saveBtn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    onChange: Function,
    saveData: Function
  },
  data () {
    return {
      user: JSON.parse(JSON.stringify(this.$store.getters.user)),
      newVat: '',
      repeatVat: '',
      saveBtn: false
    }
  },
  watch: {
    newVat (val) { this.newVat = this.changeVal(val) },
    repeatVat (val) { this.repeatVat = this.changeVal(val) }
  },
  methods: {
    changeVal (val) {
      val = val.split(' ').join('')
      if (val.length > 2) {
        val = val.substring(0, 2).toUpperCase() + ' ' + val.substring(2, val.length)
      }
      if (val.length > 6) {
        val = val.substring(0, 6).toUpperCase() + ' ' + val.substring(6, val.length)
      }
      if (val.length > 10) {
        val = val.substring(0, 10).toUpperCase() + ' ' + val.substring(10, val.length)
      }
      if (val.length > 14) {
        val = val.substring(0, 14).toUpperCase() + ' ' + val.substring(14, val.length)
      }
      return val
    },
    checkVat () {
      var send = {
        vat: this.newVat.split(' ').join('')
      }
      this.$flix_post({
        url: 'user/validate_ustid',
        data: send,
        callback: function (ret) {
          if (ret.data[0]) {
            if (ret.data[1].valid === true) {
              this.user.vat = ret.data[1]
              this.user.vat.vat_number = send.vat
              this.saveData(this.user)
            }
          }
        }.bind(this)
      })
    },
    checkSaveButton () {
      this.saveBtn = true
      if (this.newVat !== this.repeatVat) {
        this.saveBtn = false
        return false
      }
      if (this.newVat.split(' ').join('').length < 5 || this.repeatVat.split(' ').join('').length < 5) {
        this.saveBtn = false
        return false
      }
      // this.user.vat = this.newVat.split(' ').join('')
    }
  },
  mounted () {
    if (typeof this.user.vat === 'undefined') {
      this.user.vat = {
        vat_number: ''
      }
    }
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
