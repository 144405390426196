import { render, staticRenderFns } from "./changeVat.vue?vue&type=template&id=64f34cfe&scoped=true"
import script from "./changeVat.vue?vue&type=script&lang=js"
export * from "./changeVat.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f34cfe",
  null
  
)

export default component.exports